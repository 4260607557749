import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportListComponent } from './report-list/report-list.component';
import { ReportRoutingModule } from './report-routing.module';
import { RouterLink } from '@angular/router';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { ReportComponent } from './report.component';
import { CardComponentModule, ExportService, SharedModule } from 'lib-trend-core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ReportListContactComponent } from './report-list-contact/report-list-contact.component';
import { ReportListImportationComponent } from './report-list-importation/report-list-importation.component';
import { ReportCsatListComponent } from './report-csat-list/report-csat-list.component';

@NgModule({
  declarations: [
    ReportListComponent,
    ReportComponent,
    ReportListContactComponent,
    ReportListImportationComponent,
    ReportCsatListComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CardComponentModule,
    ReportRoutingModule,
    RouterLink,
    MatSelectModule,
    MatOptionModule,
    NgxChartsModule
  ],
  exports: [
    ReportListComponent,
    ReportComponent
  ],
  providers:[ExportService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ReportModule { }
