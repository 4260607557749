import { Component, Injectable, Injector, OnInit } from '@angular/core';

import { AbstractComponent } from 'lib-trend-core';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrl: './report.component.scss'
})
@Injectable()
export class ReportComponent extends AbstractComponent implements OnInit{
  

  constructor(
    injetor: Injector,
  ) {
    super(injetor);
  }
  ngOnInit(): void {
  }
  
  viewPerformece(title: string): void {
    this.router.navigate([`/report/view-performance`], { 
      queryParams: { title },
      relativeTo: this.route 
    });
  }

  viewContact(title: string): void {
    this.router.navigate([`/report/view-contact`], { 
      queryParams: { title },
      relativeTo: this.route 
    });
  }

  viewCloud(title: string): void {
    this.router.navigate([`/report/view`], { 
      queryParams: { title },
      relativeTo: this.route 
    });
  }

  viewImportation(): void {
    this.router.navigate([`/report/view-importation`], { 
      relativeTo: this.route 
    });
  }

  viewCSATReport(): void {
    this.router.navigate([`/report/view-csat`], { 
      relativeTo: this.route 
    });
  }
}
