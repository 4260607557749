<div class="flex flex-1 flex-col px-5">

  <div class="mb-4 flex justify-between items-center">
    <div>
      <h1 class="font-black text-[20px] text-black">Relatório CSAT</h1>
      <h6 class="text-zinc-400 font-light text-sm my-2">
        Busque filtro para consultar com mais detalhes os relatórios
      </h6>
    </div>

  </div>
  <hr />


  <div class="w-full flex flex-col items-center">
    <section class="w-full flex flex-wrap">
      <div class="w-full flex flex-col">
        <div class="flex flex-1 flex-col">
          <div class="form-wrapper">
            <form [formGroup]="reportForm">
              <div class="form-content">
                <div class="w-full flex gap-1">
                  <div class="flex w-full flex-col">
                    <div class="flex gap-2 justify-end">
                      <div class="bg-white flex p-[10px] items-center h-[70%] rounded-lg global-filter">
                        <mat-date-range-input [rangePicker]="picker" formGroupName="dateRange">
                          <input matStartDate placeholder="Data inicial" formControlName="dateStartCurrent"
                            class="min-w-[140px] max-w-[160px] text-sm px-2" readonly (focus)="picker.open()">
                          <input matEndDate placeholder="Data final" formControlName="dateEndCurrent"
                            class="min-w-[140px] max-w-[160px] text-sm px-2" readonly (focus)="picker.open()">
                        </mat-date-range-input>

                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>

                        <mat-date-range-picker #picker>
                          <mat-datepicker-actions>
                            <mat-select class="input-field rounded-lg" (selectionChange)="onPeriodChange($event)"
                              placeholder="Selecione">
                              <mat-option value="1">Hoje</mat-option>
                              <mat-option value="7">Últimos 7 dias</mat-option>
                              <mat-option value="15">Últimos 15 dias</mat-option>
                              <mat-option value="30">Últimos 30 dias</mat-option>
                              <mat-option value="60">Últimos 60 dias</mat-option>
                            </mat-select>
                            <button mat-raised-button matDatepickerApply class="btnOk" (click)="picker.close()">
                              OK
                            </button>
                          </mat-datepicker-actions>
                        </mat-date-range-picker>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div mat-button [matMenuTriggerFor]="menuChannel"
                      class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm rounded-lg global-filter">
                      <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                          fill="#667085" />
                      </svg>
                      <span class="truncate-text">{{ labelChannel }}</span>
                    </div>

                    <mat-menu #menuChannel="matMenu">
                      <button mat-menu-item [value]="-1" (click)="onChannelSelect(-1)">
                        Todos
                      </button>
                      <button mat-menu-item *ngFor="let item of channelAttendant" [value]="item?._id"
                        (click)="onChannelSelect(item?._id, item.name)">
                        {{ item.name }}
                      </button>
                    </mat-menu>
                  </div>

                  <div>
                    <div mat-button [matMenuTriggerFor]="menuDepartment"
                      class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm rounded-lg global-filter">
                      <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                          fill="#667085" />
                      </svg>
                      <span class="truncate-text">{{ labelDepartment }}</span>
                    </div>

                    <mat-menu #menuDepartment="matMenu">
                      <button mat-menu-item [value]="-1" (click)="onDepartmentSelect(-1)">
                        Todos
                      </button>
                      <button mat-menu-item *ngFor="let item of department" [value]="item?._id"
                        (click)="onDepartmentSelect(item?._id, item.name)">
                        {{ item.name }}
                      </button>
                    </mat-menu>
                  </div>

                  <div>
                    <div mat-button [matMenuTriggerFor]="menuAttendant"
                      class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm rounded-lg global-filter">
                      <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                          fill="#667085" />
                      </svg>
                      <span class="truncate-text">{{ labelAttendant }}</span>
                    </div>

                    <mat-menu #menuAttendant="matMenu">
                      <button mat-menu-item [value]="-1" (click)="onAttendantSelect(-1)">
                        Todos
                      </button>
                      <button mat-menu-item *ngFor="let item of userAttendant" [value]="item?._id"
                        (click)="onAttendantSelect(item?._id, item.name)">
                        {{ item.name }}
                      </button>
                    </mat-menu>
                  </div>

                  <div>
                    <div mat-button [matMenuTriggerFor]="menuNote"
                      class="flex justify-center items-center bg-white gap-2 p-2.5 ms-2 text-sm rounded-lg global-filter">
                      <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
                          fill="#667085" />
                      </svg>
                      <span class="truncate-text">{{ labelFeedbackCSAT }}</span>
                    </div>

                    <mat-menu #menuNote="matMenu">
                      <button mat-menu-item [value]="-1" (click)="onFeedbackCSATSelect(-1)">
                        Todos
                      </button>
                      <button mat-menu-item [value]="1"
                        (click)="onFeedbackCSATSelect(1, '1')">
                        1
                      </button>
                      <button mat-menu-item [value]="2"
                        (click)="onFeedbackCSATSelect(2, '2')">
                        2
                      </button>
                      <button mat-menu-item [value]="3"
                        (click)="onFeedbackCSATSelect(3, '3')">
                        3
                      </button>
                      <button mat-menu-item [value]="4"
                        (click)="onFeedbackCSATSelect(4, '4')">
                        4
                      </button>
                      <button mat-menu-item [value]="5"
                        (click)="onFeedbackCSATSelect(5, '5')">
                        5
                      </button>
                    </mat-menu>
                  </div>
                  <button
                    class="ml-4 w-[350px] h-[40px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal hover:bg-[#1340f6]"
                    (click)="exportFile()">
                    <i class="ph ph-export text-[24px]"></i>
                    Exportar
                  </button>
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>

      <div class="w-full mt-4 mb-3">
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-3 sm:gap-3">
          <card-graphic-small-component *ngFor="let level of satisfactionLevels" [label]="level.name"
            [total]="level.value.toString()" [contactInfoChart]="level.percentage" [graphicType]="'percentage'"
            [textColor]="'#000'" [totalContact]="getTotalResponses()" [datasetTitle]="'respostas'" class="w-full">
            <p class="text-sm text-gray-600 mt-1">
              {{ getTotalResponses() === 0 ? '-' : (level.value / getTotalResponses() * 100).toFixed(1) + '%' }}
            </p>
          </card-graphic-small-component>
        </div>
      </div>


      <div class="w-full rounded-lg">
        <div class="gap-3 mb-3">
          <div class="p-3 rounded-lg bg-white shadow-global">
            <h2 class="text-xl font-semibold mb-4">Níveis de Satisfação</h2>
            <div class="w-full flex justify-center items-center">
              <ngx-charts-bar-horizontal [scheme]="colorScheme" [results]="satisfactionLevels" [gradient]="false"
                [xAxis]="true" [yAxis]="true" [legend]="true" [legendTitle]="'Legenda'" [showXAxisLabel]="true"
                [showYAxisLabel]="true" xAxisLabel="Quantidade" yAxisLabel="Nível" [sort]="false"
                style="width: 100%; height: 300px">
              </ngx-charts-bar-horizontal>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full rounded-lg mb-3">
        <div class="shadow-global rounded-lg" *ngIf="pager.total > 0">
          <mat-card class="shadow-global">
            <mat-card-content class="content-bird">
              <div class="table-container">
                <table class="min-w-full divide-gray-100 divide-y-[3px]">
                  <thead class="bg-gray-50  ">
                    <tr>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">
                        Data</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">
                        Nome</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">
                        Telefone</th>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Canal</th>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">
                        Departamento</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">
                        Atendente</th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">
                        Nota</th>
                    </tr>
                  </thead>
                  <tbody class="divide-gray-100 divide-y-[3px] bg-white">

                    <tr class="rounded-none" *ngFor="let item of pager.list">
                      <td class="px-3 py-4 text-sm">
                        <p>{{item?.updatedAt | date: 'dd/MM/yyy'}}</p>
                      </td>
                      <td class="px-3 py-4 text-sm">
                        <p>{{item?.contact?.name}}</p>
                      </td>
                      <td class="px-3 py-4 text-sm">
                        <p>{{item?.contact?.phone}}</p>
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <p>{{item?.channel?.name}}</p>
                      </td>
                      <td class="px-3 py-4 text-sm">
                        <p>{{item?.department?.name}}</p>
                      </td>
                      <td class="px-3 py-4 text-sm">
                        <p>{{item?.user?.name}}</p>
                      </td>
                      <td class="px-3 py-4 text-sm">
                        <p>{{item?.feedbackCSAT}}</p>
                      </td>
                    </tr>
                    <tr class=""></tr>
                  </tbody>
                </table>
              </div>
            </mat-card-content>
            <paginator *ngIf="pager.total > pager.perPage" class="flex justify-content-center mt-3 mb-3" [pager]="pager"
              (onPager)="loadPage($event)">
            </paginator>
          </mat-card>


        </div>
        <div class="rounded-lg shadow-global " *ngIf="pager.total == 0">
          <mat-card class="card-container-campaing-not rounded-lg shadow-global ">
            <mat-card-header>
              <mat-card-title class="titleChannel">Nenhum registro encontrado</mat-card-title>
            </mat-card-header>
            <mat-card-content class="content-card ">
              <div class="metrics-wrapper">
                <div class="metric">
                  <span>Altere no filtro para buscar mais resultados!</span>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </section>
  </div>
</div>