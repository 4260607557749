import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateSelectionModelChange, MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AbstractComponent, Channel, ChannelService, Department, DepartmentService, User, UserService, ReportService, Pager, Attendance, ModalReportAttendanceComponent, ExportService, AttendanceService, ResponseFiltersAttendanceTypes } from 'lib-trend-core';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { saveAs } from 'file-saver';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-report-csat-list',
  templateUrl: './report-csat-list.component.html',
  styleUrl: './report-csat-list.component.scss'
})
export class ReportCsatListComponent extends AbstractComponent implements OnInit {
  @ViewChild('picker', { static: true }) picker: MatDatepicker<Date>;
  reportForm: any;
  userAttendant: User[];
  department: Department[];
  channelAttendant: Channel[];
  labelChannel: string = 'Canal';
  labelDepartment: string = 'Departamento';
  labelAttendant: string = 'Atendente';
  labelFeedbackCSAT: string = 'Nota';
  colorScheme: any = {
    domain: [
      '#4213F6',
      '#6137FB',
      '#7F5BFF',
      '#9A78FD',
      '#B5A8FB'
    ]
  };

  view: [number, number] = [850, 600];
  graphGroupNote: any;
  graphPie: any;
  detailedSatisfactionLevels: any[] = [];
  numberCard: any;
  satisfactionLevels: any[] = [
    { name: '1 - Péssimo', value: 0},
    { name: '2 - Ruim', value: 0 },
    { name: '3 - Regular', value: 0 },
    { name: '4 - Bom', value: 0 },
    { name: '5 - Ótimo', value: 0 },
  ];

  pager: Pager<any> = new Pager<any>({ perPage: 10 });
  isPicker: boolean = false;

  public chartWidth: number = 0;
  public chartHeight: number = 300;
  public legendPosition: string = 'right';

  constructor(
    injetor: Injector,
    private fb: FormBuilder,
    public attendanceService: AttendanceService,
    public userSerivce: UserService,
    public departmentService: DepartmentService,
    public channelService: ChannelService,
    public reportService: ReportService,
    public exportService: ExportService,
    public override router: Router,
    public dialog: MatDialog,
  ) {
    super(injetor);
    this.loadingContent = true;
  }

  ngOnInit(): void {
    this.updateChartDimensions();
    window.addEventListener('resize', () => this.updateChartDimensions());
    this.createForm();
    this.getByUserAttendant();
    this.setDefaultDateRange();
    this.getDepartmentByCompany();
    this.getByAllChannel();
    this.initializeData();


    this.reportForm.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
    ).subscribe(val => {
      this.onFormChange(val);
    });
  }

  private setDefaultDateRange(): void {
    const today = new Date();
    const startDate = new Date(new Date().setDate(today.getDate() - 30));

    const dateRangeGroup = this.reportForm.get('dateRange') as FormGroup;
    dateRangeGroup.patchValue({
      dateStartCurrent: startDate,
      dateEndCurrent: today
    });
  }

  private updateChartDimensions() {
    const screenWidth = window.innerWidth;

    this.legendPosition = screenWidth < 768 ? 'below' : 'right';

    this.chartHeight = screenWidth < 768 ? 300 : 400;
  }

  private initializeData() {
    const dateRange = this.reportForm.value.dateRange;
    if (dateRange && dateRange.dateStartCurrent && dateRange.dateEndCurrent) {
      const formsValue = {
        dateStartCurrent: dateRange.dateStartCurrent.getTime(),
        dateEndCurrent: dateRange.dateEndCurrent.getTime(),
        department: this.reportForm.value.department === -1 ? null : this.reportForm.value.department,
        attendant: this.reportForm.value.attendant === -1 ? null : this.reportForm.value.attendant,
        channel: this.reportForm.value.channel === -1 ? null : this.reportForm.value.channel,
        feedbackCSAT: this.reportForm.value.feedbackCSAT === -1 ? null : this.reportForm.value.feedbackCSAT,
        page: '1'
      } as ResponseFiltersAttendanceTypes;
      this.getReportItems(formsValue);
    }
  }


  private createForm(): void {
    const today = new Date();
    const yesterday = new Date(today);

    yesterday.setDate(today.getDate() - 1);
    this.reportForm = this.fb.group({
      dateRange: this.fb.group({
        dateStartCurrent: [null, []],
        dateEndCurrent: [null, []],
      }),
      department: [-1],
      attendant: [-1],
      channel: [-1],
      feedbackCSAT: [-1]
    });
  }

  onFormChange(changes: any) {
    if (changes.dateRange.dateStartCurrent && changes.dateRange.dateEndCurrent) {
      const formsValue = {
        dateStartCurrent: this.reportForm.value.dateRange.dateStartCurrent.getTime(),
        dateEndCurrent: this.reportForm.value.dateRange.dateEndCurrent.getTime(),
        department: changes.department === -1 ? null : changes.department,
        attendant: changes.attendant === -1 ? null : changes.attendant,
        channel: changes.channel === -1 ? null : changes.channel,
        feedbackCSAT: changes.feedbackCSAT === -1 ? null : changes.feedbackCSAT,
        page: '1'
      } as ResponseFiltersAttendanceTypes;
      this.getReportItems(formsValue);
    }
  }
  onPeriodChange(event: MatSelectChange) {
    const today = new Date();
    let startDate: Date;

    switch (event.value) {
      case '1':
        startDate = new Date();
        break;
      case '7':
        startDate = new Date(new Date().setDate(today.getDate() - 7));
        break;
      case '15':
        startDate = new Date(new Date().setDate(today.getDate() - 15));
        break;
      case '30':
        startDate = new Date(new Date().setDate(today.getDate() - 30));
        break;
      case '60':
        startDate = new Date(new Date().setDate(today.getDate() - 60));
        break;
      default:
        startDate = today;
    }

    this.reportForm.get('dateRange').patchValue({
      dateStartCurrent: startDate,
      dateEndCurrent: today
    });

    this.isPicker = false;
    this.picker.close();
  }

  loadPage(page: number) {
    this.pager.page = page;
    const formsValue = {
      dateStartCurrent: this.reportForm.value.dateRange.dateStartCurrent.getTime(),
      dateEndCurrent: this.reportForm.value.dateRange.dateEndCurrent.getTime(),
      department: this.reportForm.value.department === -1 ? null : this.reportForm.value.department,
      attendant: this.reportForm.value.attendant === -1 ? null : this.reportForm.value.attendant,
      channel: this.reportForm.value.channel === -1 ? null : this.reportForm.value.channel,
      feedbackCSAT: this.reportForm.value.feedbackCSAT === -1 ? null : this.reportForm.value.feedbackCSAT,
      page: this.pager.page + ''
    } as ResponseFiltersAttendanceTypes;
    this.getReportItems(formsValue);
  }

  getByUserAttendant() {
    this.userSerivce.findByAttendant().subscribe({
      next: (users: User[]) => {
        this.userAttendant = users;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getDepartmentByCompany() {
    this.departmentService.getList().subscribe({
      next: (dresponseDepart: Department[]) => {
        this.department = dresponseDepart;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  getByAllChannel() {
    this.channelService.getByAllChannel().subscribe({
      next: (channel: Channel[]) => {
        this.channelAttendant = channel;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }


  getReportItems(form: ResponseFiltersAttendanceTypes) {
    this.attendanceService.getReportCSAT(form).subscribe({
      next: (resp: any) => {
        this.createGraphBar(resp);
        this.createGraphPie(resp);
        this.createNumberCard(resp);
        this.createSatisfactionLevels(resp);
        this.createDetailedSatisfactionLevels(resp);
        this.pager = resp.pager;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    });
  }


  private createNumberCard(resp: any) {
    let total = 0;
    if (resp.csatSendNotAnswered) {
      total += resp.csatSendNotAnswered.count;
    }
    if (resp.csatSendAnswered) {
      total += resp.csatSendAnswered.count;
    }
    this.numberCard = [
      {
        "name": "Total de Pesquisas",
        "value": total,
      },
      {
        "name": "Pesquisas Não Respondidas",
        "value": resp.csatSendNotAnswered ? resp.csatSendNotAnswered.count : 0,
      },
      {
        "name": "Pesquisas Respondidas",
        "value": resp.csatSendAnswered ? resp.csatSendAnswered.count : 0,
      }
    ];
  }

  private calculateSatisfactionLevels(graphGroupNote: any[]) {
    const levels = {
      insatisfeito: 0,
      neutro: 0,
      satisfeito: 0
    };

    if (!graphGroupNote) return levels;

    graphGroupNote.forEach(item => {
      if (item.feedback <= 2) {
        levels.insatisfeito += item.count;
      } else if (item.feedback <= 4) {
        levels.neutro += item.count;
      } else {
        levels.satisfeito += item.count;
      }
    });

    return levels;
  }

  private createGraphPie(resp: any) {
    if (!resp.graphGroupNote || !resp.csatSendAnswered || !resp.csatSendAnswered.count) {
      this.graphPie = [];
      return;
    }

    const levels = this.calculateSatisfactionLevels(resp.graphGroupNote);

    if (resp.csatSendAnswered.count === 0) {
      this.graphPie = [];
      return;
    }

    this.graphPie = [
      { name: "Insatisfeito", value: levels.insatisfeito },
      { name: "Neutro", value: levels.neutro },
      { name: "Satisfeito", value: levels.satisfeito }
    ];
  }

  private createGraphBar(resp: any) {
    if (!resp.graphGroupNote) {
      this.graphGroupNote = [];
      return;
    }

    const levels = this.calculateSatisfactionLevels(resp.graphGroupNote);
    this.graphGroupNote = [
      {
        name: "Insatisfeito",
        value: levels.insatisfeito,
        color: '#4213F6'
      },
      {
        name: "Neutro",
        value: levels.neutro,
        color: '#7F5BFF'
      },
      {
        name: "Satisfeito",
        value: levels.satisfeito,
        color: '#AA98EC'
      }
    ];
  }

  private calculatePercentage(value: number, total: number): number {
    if (total === 0) return 0;
    return Math.round((value / total) * 100);
  }

  private createSatisfactionLevels(resp: any) {
    const feedback1 = resp.graphGroupNote?.find(item => item.feedback === 1)?.count || 0;
    const feedback2 = resp.graphGroupNote?.find(item => item.feedback === 2)?.count || 0;
    const feedback3 = resp.graphGroupNote?.find(item => item.feedback === 3)?.count || 0;
    const feedback4 = resp.graphGroupNote?.find(item => item.feedback === 4)?.count || 0;
    const feedback5 = resp.graphGroupNote?.find(item => item.feedback === 5)?.count || 0;
    this.satisfactionLevels = [
      {
        name: '1 - Péssimo',
        value: feedback1,
        percentage: this.calculatePercentage(feedback1, resp.csatSendAnswered?.count || 0),
      },
      {
        name: '2 - Ruim',
        value: feedback2,
        percentage: this.calculatePercentage(feedback2, resp.csatSendAnswered?.count || 0),
      },
      {
        name: '3 - Regular',
        value: feedback3,
        percentage: this.calculatePercentage(feedback3, resp.csatSendAnswered?.count || 0),
      },
      {
        name: '4 - Bom',
        value: feedback4,
        percentage: this.calculatePercentage(feedback4, resp.csatSendAnswered?.count || 0),
      },
      {
        name: '5 - Ótimo',
        value: feedback5,
        percentage: this.calculatePercentage(feedback5, resp.csatSendAnswered?.count || 0),
      }
    ];
  }

  private createDetailedSatisfactionLevels(resp: any) {
    const detailedLevels = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

    if (resp.graphGroupNote && Array.isArray(resp.graphGroupNote)) {
      resp.graphGroupNote.forEach(item => {
        if (item.feedback >= 1 && item.feedback <= 5) {
          detailedLevels[item.feedback] += item.count;
        }
      });
    }

    const total = resp.csatSendAnswered ? resp.csatSendAnswered.count : 0;

    const colors = {
      1: '#4213F6',
      2: '#7F5BFF',
      3: '#AA98EC',
      4: '#FF6B6B',
      5: '#FFD93D'
    };

    this.detailedSatisfactionLevels = [
      {
        name: `Nota 1 (Qtd: ${detailedLevels[1]}, ${this.calculatePercentage(detailedLevels[1], total)}%)`,
        value: detailedLevels[1],
        percentage: this.calculatePercentage(detailedLevels[1], total),
        color: colors[1]
      },
      {
        name: `Nota 2 (Qtd: ${detailedLevels[2]}, ${this.calculatePercentage(detailedLevels[2], total)}%)`,
        value: detailedLevels[2],
        percentage: this.calculatePercentage(detailedLevels[2], total),
        color: colors[2]
      },
      {
        name: `Nota 3 (Qtd: ${detailedLevels[3]}, ${this.calculatePercentage(detailedLevels[3], total)}%)`,
        value: detailedLevels[3],
        percentage: this.calculatePercentage(detailedLevels[3], total),
        color: colors[3]
      },
      {
        name: `Nota 4 (Qtd: ${detailedLevels[4]}, ${this.calculatePercentage(detailedLevels[4], total)}%)`,
        value: detailedLevels[4],
        percentage: this.calculatePercentage(detailedLevels[4], total),
        color: colors[4]
      },
      {
        name: `Nota 5 (Qtd: ${detailedLevels[5]}, ${this.calculatePercentage(detailedLevels[5], total)}%)`,
        value: detailedLevels[5],
        percentage: this.calculatePercentage(detailedLevels[5], total),
        color: colors[5]
      }
    ];
  }



  isPeriodEmpty(): boolean {
    const dateRange = this.reportForm.get('dateRange').value;
    return !dateRange?.dateStartCurrent || !dateRange?.dateEndCurrent;
  }

  exportFile() {
    if (
      !this.reportForm.value.dateRange.dateStartCurrent ||
      !this.reportForm.value.dateRange.dateEndCurrent
    ) {
      this.alertService.error("Selecione um período antes de exportar a planilha.");
      return;
    }

    const accumulatedData: Attendance[] = [];
    const fetchPageData = (page: number) => {
      const form = {
        dateStartCurrent: this.reportForm.value.dateRange.dateStartCurrent.getTime(),
        dateEndCurrent: this.reportForm.value.dateRange.dateEndCurrent.getTime(),
        department: this.reportForm.value.department === -1 ? null : this.reportForm.value.department,
        attendant: this.reportForm.value.attendant === -1 ? null : this.reportForm.value.attendant,
        channel: this.reportForm.value.channel === -1 ? null : this.reportForm.value.channel,
        feedbackCSAT: this.reportForm.value.feedbackCSAT === -1 ? null : this.reportForm.value.feedbackCSAT,
        page: page.toString()
      } as ResponseFiltersAttendanceTypes;

      this.attendanceService.getReportCSAT(form).subscribe({
        next: (resp: any) => {
          accumulatedData.push(...resp.pager.list);
          const totalPages = Math.ceil(resp.pager.total / resp.pager.perPage);
          if (page < totalPages) {
            fetchPageData(page + 1);
          } else {
            this.exportService.generateExcelCSAT(accumulatedData).then((bufferExport) => {
              const blob = new Blob([bufferExport], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });
              saveAs(blob, 'relatorio-csat.xlsx');
            });
          }
        },
        error: (err) => {
          this.alertService.error(err.error.message);
          this.loadingContent = false;
        },
      });
    };
    this.loadingContent = true;
    fetchPageData(1);
  }

  getTotalResponses(): number {
    return this.satisfactionLevels.reduce((acc, curr) => acc + curr.value, 0);
  }

  onChannelSelect(value: any, label: string = 'Canal'): void {
    this.reportForm.get('channel')?.setValue(value);
    this.labelChannel = label;
  }

  onDepartmentSelect(value: any, label: string = 'Departamento'): void {
    this.reportForm.get('department')?.setValue(value);
    this.labelDepartment = label;
  }

  onAttendantSelect(value: any, label: string = 'Atendente'): void {
    this.reportForm.get('attendant')?.setValue(value);
    this.labelAttendant = label;
  }

  onFeedbackCSATSelect(value: any, label: string = 'Nota'): void {
    this.reportForm.get('feedbackCSAT')?.setValue(value);
    this.labelFeedbackCSAT = label;
  }


}
